<template>
  <div class="usershow">
    <div class="nav">
      <ul>
        <li v-for="(item,index) in headernav" :key="index"
        @click="this.$router.push(item.path)">
          {{item.text}}
        </li>
        <li @click="toSell"><a>{{$t('m.Header.Switch_merchant_side')}}</a></li>
      </ul>
      <div id="search">
        <div class="logosearch">
          <div id="logo">
            <img @click="this.$router.push('/')" src="../../assets/logo2.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="ctleft" :style="$t('m.personalcenter_myorder.ctleftwidth')">
        <ul class="showul">
          <li
          class="liname"
          v-for="(i,k) in leftList"
          :key="k"
          :class="k==isact?'activeli':''"
          @click="actColor(k)">
          {{i}}</li>
        </ul>
      </div>

      <div class="ctright">
        <component :is="isPage">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'

// 我的订单
import myOrder from './myOrder.vue'
// 资金账户
import isMoney from './isMoney.vue'
import bandBank from './bandBank.vue'
// // 我的转售
import myResell from './myResell.vue'

// 收货地址
import AreaList from './AreaList.vue'
// 发票信息
import InvoiceList from './InvoiceList.vue'
// 开通资金账户
import Money from './Money.vue'
// 安全设置
import secSettings from './securitySettings.vue'
// 个人资料
import myMessage from './myMessage.vue'
// 仓储管理
import warehouse from './warehouse/index.vue'
import emitter from '../../tools/bus'
import i18n from '@/tools/i18n.js'
const { t } = i18n.global

const headernav = [
  { icon: '', text: t('m.Header.home_page'), path: '/' },
  { icon: '', text: t('m.Header.login_again'), path: '/loginandregister' },
  { icon: '', text: t('m.Header.mobile_terminal'), path: '/' }
  // { icon: '', text: '切换商家端', path: '/merchantworkbench' }
]
export default {
  name: 'UserShow',
  data () {
    return {
      isPage: 'myMessage',
      isact: 0,
      // leftList: ['个人资料', '资金账户', '开通资金账户', '绑定银行卡', '我的订单', '我的转售', '收货地址', '发票信息', '安全设置']
      // leftList: [
      //   '个人资料',
      //   '资金账户',
      //   '开通资金账户',
      //   '绑定银行卡',
      //   '我的订单',
      //   '收货地址',
      //   '发票信息',
      //   '安全设置'
      // ]
      leftList: [
        this.$t('m.personalcenter_myorder.personal_data'),
        this.$t('m.personalcenter_myorder.Funding_account'),
        this.$t('m.personalcenter_myorder.Open_a_funding_account'),
        this.$t('m.personalcenter_myorder.Bind_a_bank_card'),
        this.$t('m.personalcenter_myorder.My_Orders'),
        this.$t('m.personalcenter_myorder.shipping_address'),
        this.$t('m.buy.invoice_information'),
        this.$t('m.personalcenter_myorder.Security_settings'),
        '仓储管理'
      ]
      // leftList: ['个人资料', '资金账户', '开通资金账户', '我的订单', '收货地址', '发票信息', '安全设置']
    }
  },
  components: {
    myMessage, myOrder, AreaList, Money, myResell, isMoney, secSettings, InvoiceList, bandBank, warehouse
  },
  methods: {
    actColor (k) {
      this.$store.state.personalNumber = k
      // this.$store.state.citysId
      this.isact = k
      switch (k) {
        case 0:
          this.isPage = 'myMessage'
          break
        case 1:
          this.isPage = 'isMoney'
          break
        case 2:
          this.isPage = 'Money'
          break
        case 3:
          this.isPage = 'bandBank'
          break
        case 4:
          this.isPage = 'myOrder'
          break
        case 5:
          this.isPage = 'AreaList'
          break
        case 6:
          this.isPage = 'InvoiceList'
          break
        case 7:
          this.isPage = 'secSettings'
          break
        case 8:
          this.isPage = 'warehouse'
          break
        default:
          break
      }
    },
    toSell () {
      if (!JSON.parse(getStore('usermessage'))) {
        ElMessage.warning({
          message: '没有检测到登录信息，请登录后操作',
          duration: 2000
        })
        return
      }
      if (JSON.parse(getStore('usermessage')).type === 0) {
        ElMessage.error('没有相关权限')
        return ''
      } else {
        this.$router.push('/merchantworkbench')
        // setTimeout(() => {
        //   window.location.reload() // 页面重新加载
        // }, 100)
      }
    }
  },
  mounted () {
    // window.location.reload()
    emitter.on('toUserShow', (data) => {
      console.log(data)
      // this.isPage = 6
      this.actColor(data)
    })
    if (this.$store.state.personalNumber !== '') {
      this.actColor(this.$store.state.personalNumber)
    }
  },
  setup () {
    return {
      headernav
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.usershow{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  min-height: 100vh;
  // display: flex;
  // flex-flow: wrap;
  .nav{
    // height: 30px;
    flex: 1;
    width: 100%;
    height: 150px;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .content{
    // flex: 1;
    width: 1200px;
    // max-width: 12000px;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    .ctleft{
      background: $divbgColor;
      // width: 160px;
      min-width: 160px;
      margin-right: 10px;
      height: 880px;
      .showul{
        display: flex;
        flex-flow: column;
        padding: 32px 0;
        .liname{
          padding: 0 20px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          height: 36px;
          line-height: 36px;
          cursor: pointer;
          transition: all .2s;
        }
        .activeli{
          background: #F7F7F7;
          color: #C6824E;
        }
      }
    }
    .ctright{
      flex: 1;
      background: #ffffff;
      // height: 100%;
      // min-height: 950px;
    }
  }
}

</style>
